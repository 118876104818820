import { normalizeString, stringMatch } from 'common/utils';

import { City } from '../../../service/types/generated/index';

export const filterDeliveryZones = (cities: Array<City>, match: string): Array<City> => {
  const normalizedMatch = normalizeString(match);

  return cities
    .map(city => {
      const hasTitleMatch =
        stringMatch([city], normalizedMatch, c => [c.titleEn, normalizeString(c.titleAr)]).length > 0;

      let filteredAreas = city.areas;
      let hasAreaMatch = false;

      if (!hasTitleMatch) {
        filteredAreas = stringMatch(filteredAreas, normalizedMatch, area => [
          area.titleEn,
          normalizeString(area.titleAr),
        ]);
        hasAreaMatch = filteredAreas.length > 0;
      }

      return { ...city, areas: filteredAreas, hasTitleMatch, hasAreaMatch };
    })
    .filter(city => city.hasAreaMatch || city.hasTitleMatch)
    .map(({ hasTitleMatch, hasAreaMatch, ...city }) => city);
};
