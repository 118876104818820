import React, { useState } from 'react';
import { Collapse, ShimmerComponent, useTheme } from '@zydalabs/storefront-components';
import { Typography } from '@zydalabs/zac-react';
import styles from './styles';
import { Country } from '../../../../service/types/generated';

type CountriesTypeProps = {
  setSelectedCountry: (x: Country) => void;
  selectedCountry: Country;
  countries: Array<Country>;
  isLoading?: boolean;
  isArabic?: boolean;
};

const Countries: React.FC<CountriesTypeProps> = ({ setSelectedCountry, selectedCountry, countries, isLoading, isArabic }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const theme: any = useTheme();

  const { StyledCountryWrapper, StyledCountryShimmer } = styles;
  const titleLocalized = `title${!isArabic ? 'En' : 'Ar'}`;

  const handleCountryClick = country => {
    setSelectedCountry(country);
    setIsCollapseOpen(!isCollapseOpen);
  };

  if (isLoading) {
    return (
      <StyledCountryShimmer>
        <ShimmerComponent.ShimmerEffect>
          <ShimmerComponent.NoAnimationBox
            alignItems="center"
            height="fit-content"
            py="12px"
            width={1}
            justifyContent="space-between"
          >
            <ShimmerComponent.Rect mt="4px" height="24" width="100%" />
          </ShimmerComponent.NoAnimationBox>
        </ShimmerComponent.ShimmerEffect>
      </StyledCountryShimmer>
    );
  }

  const CountriesList = (
    <>
      {selectedCountry &&
        countries
          .filter(({ code }) => selectedCountry.code !== code)
          .map(country => (
            <StyledCountryWrapper key={country.code} onClick={() => handleCountryClick(country)}>
              <Typography testId={`${country.id}_country_name`} variant="heading16">
                {country[titleLocalized]}
              </Typography>
            </StyledCountryWrapper>
          ))}
    </>
  );

  return (
    <>
      {countries && countries.length > 1 && selectedCountry && (
        <Collapse
          testId="collapse"
          title={selectedCountry[titleLocalized]}
          body={CountriesList}
          isOpen={isCollapseOpen}
          onChange={() => setIsCollapseOpen(!isCollapseOpen)}
          bg={theme.color.background}
        />
      )}
    </>
  );
};

export default Countries;
