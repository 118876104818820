import { gql } from 'graphql-request';

const storeCountriesQuery = gql`
  query store($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      countries {
        code
        id
        currency
        titleAr
        titleEn
        flag
        timeZone
      }
    }
  }
`;

export default storeCountriesQuery;
