import { countryDetailsWithAreasQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import { Country } from '../../types/generated';
import ServiceConfigs from '../../config';
import { useQuery } from '../../utils';

type UseCountryDetailsType = ({ id, initialData }: { id?: Country['id']; initialData?: Country }) => QueryHookResponse<Country>;

const useCountryDetailsWithAreas: UseCountryDetailsType = ({ id, initialData } = {}) =>
  useQuery<Country>({
    query: countryDetailsWithAreasQuery,
    variables: id ? { subdomain: ServiceConfigs.getSubDomain(), id } : null,
    handler: (res: any) => res.store.countries[0],
    config: { initialData },
  });

export default useCountryDetailsWithAreas;
