import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Collapse, ShimmerComponent, Search, useTheme, Separator } from '@zydalabs/storefront-components';
import { Typography } from '@zydalabs/zac-react';
import { pick } from 'ramda';
import dynamic from 'next/dynamic';

import { IcEmpty } from 'common/icons';
import { LANGUAGE_AR, FULFILLMENT_MODES, URLS } from 'common/constants';
import { useCountryDetailsWithAreas } from 'service/hooks';
import { usePersistedCountryDetails, usePersistedAddressArea, usePersistedUserAddressId } from 'common/hooks';
import { customHistory } from 'common/utils';
import useTranslation from 'next-translate/useTranslation';
import { Country, Area } from '../../../../service/types/generated';
import { useCountries } from '../../../../service/hooks/countries';
import Countries from '../Countries';
import {
  StyledWrapper,
  StyledArea,
  StyledSearch,
  StyledDeliverListWrapper,
  StyledCountryWrapper,
  StyledAreaWrapper,
} from './style';
import { filterDeliveryZones } from '../../utils';
import useSelectAddressOrArea from '../../../../common/hooks/useSelectAddressOrArea';
import { useFulfillmentSettings } from '../../../../contexts';

const EmptyStateComponent = dynamic(() => import('common/components/EmptyState'));

const DeliveryList: React.FC<{
  isAddressArea?: boolean;
  onAddressAreaSelection?: (area: Area) => void;
}> = ({ isAddressArea, onAddressAreaSelection }) => {
  const router = useRouter();
  const { t } = useTranslation('address');
  // page data
  const [persistedAddressArea] = usePersistedAddressArea();
  const [, , removePersistedAddressId] = usePersistedUserAddressId();
  const { data: countriesData, isLoading: isLoadingCountries } = useCountries();
  const { countries } = countriesData ?? { countries: [] };
  const [persistedCountryDetails, setPersistedCountryDetails] = usePersistedCountryDetails();
  const { id: persistedCountryId } = persistedCountryDetails || {};
  const { area: currentSelectedArea, orderMode } = useFulfillmentSettings();

  const chosenArea = isAddressArea ? persistedAddressArea : currentSelectedArea;

  // page states
  const [selectedCountryId, setSelectedCountryId] = useState<Country['id']>(persistedCountryId);
  const [collapseState, setCollapseState] = useState({});
  const [filterParam, setFilterParam] = useState('');

  const onCompletion = (branchId: string): void =>
    customHistory.handleOrderModeRouting({
      branchId,
      manualOrderRouting: URLS.NEW_ADDRESS,
    });
  const { handleOnAreaSelected } = useSelectAddressOrArea(onCompletion);

  // misc
  const theme: any = useTheme();
  const { data: countryDetails, isLoading } = useCountryDetailsWithAreas({ id: selectedCountryId });
  const [filteredDeliverZones, setFilteredDeliveryZones] = useState<any>();
  const isArabic = router.locale === LANGUAGE_AR;

  const sortZones = (a, b) => (isArabic ? a.titleAr : a.titleEn).localeCompare(isArabic ? b.titleAr : b.titleEn);

  const getCitiesThatHasAreas = cities => cities?.filter(city => city?.areas?.length);

  useEffect(() => {
    setFilteredDeliveryZones(countryDetails && getCitiesThatHasAreas(countryDetails.cities));
  }, [countryDetails]);

  useEffect(() => {
    if (filterParam && filteredDeliverZones?.length) setCollapseState({ [filteredDeliverZones[0].id]: true });
  }, [filterParam, filteredDeliverZones]);

  useEffect(() => {
    const selectedCityId =
      orderMode === FULFILLMENT_MODES.DELIVERY &&
      filteredDeliverZones?.find(city => city.id === chosenArea?.cityId?.toString())?.id;
    const collapseStateLength = Object.keys(collapseState).length;

    if (!collapseStateLength && selectedCityId) setCollapseState({ [selectedCityId]: true });
  }, [chosenArea?.id, filteredDeliverZones, collapseState]);

  /**
   * Set selected area and return to home page or show a popup
   *
   * @param  {Area} area
   *
   */
  const onAreaSelection = async (area: Area) => {
    removePersistedAddressId();
    if (area.id === currentSelectedArea?.id) {
      customHistory.back();
      return;
    }
    handleOnAreaSelected(area);
    setPersistedCountryDetails({ ...persistedCountryDetails, ...pick(['id', 'code', 'timeZone'], countryDetails) });
  };

  const onCountrySelected = (country: Country) => {
    setSelectedCountryId(country.id);
  };

  /**
   * Set the delivery zones after matching with the search param
   *
   * @param  {String} match
   *
   */
  const filterDeliveryZonesByParam = match => {
    if (match) {
      setFilterParam(match);
      const filteredResult = countryDetails?.cities && filterDeliveryZones(countryDetails.cities, match);
      setFilteredDeliveryZones(getCitiesThatHasAreas(filteredResult));
    } else {
      setFilterParam('');
      setFilteredDeliveryZones(getCitiesThatHasAreas(countryDetails?.cities));
    }
  };

  // Rendering
  const deliverZonesList = () => {
    if (isLoading || isLoadingCountries) {
      return (
        <>
          {Array.from([...Array(7)].keys()).map(num => (
            <div key={num}>
              <ShimmerComponent.ShimmerEffect>
                <ShimmerComponent.NoAnimationBox width="100%" key={`sh-countries-${num}`}>
                  <ShimmerComponent.Rect mt={3} mb={3} width="100%" />
                </ShimmerComponent.NoAnimationBox>
              </ShimmerComponent.ShimmerEffect>
              <Separator m={0} />
            </div>
          ))}
        </>
      );
    }

    if (countriesData?.countries?.length === 0) {
      return <EmptyStateComponent icon={IcEmpty} iconColor={theme.color.primary} subTitle={t('noAreas')} />;
    }

    if (filteredDeliverZones)
      return filteredDeliverZones.sort(sortZones).map(city => (
        <StyledAreaWrapper key={city.id} data-testid={`${city.id}_city_name`}>
          <Collapse
            title={isArabic ? city.titleAr : city.titleEn}
            isOpen={collapseState[city.id]}
            testId={city.id}
            onChange={() => setCollapseState({ [city.id]: !collapseState[city.id] })}
            body={city.areas.sort(sortZones).map(area => (
              <StyledArea
                onClick={() => (isAddressArea ? onAddressAreaSelection(area) : onAreaSelection(area))}
                key={area.id}
                isActive={orderMode === FULFILLMENT_MODES.DELIVERY && area?.id === chosenArea?.id}
              >
                <Typography
                  key={area.id}
                  variant="body16"
                  color={theme.color.headlineText}
                  cursor="pointer"
                  mx={4}
                  testId={`${area.id}_area_name`}
                >
                  {isArabic ? area.titleAr : area.titleEn}
                </Typography>
              </StyledArea>
            ))}
          />
        </StyledAreaWrapper>
      ));
    return null;
  };

  return (
    <>
      <StyledWrapper>
        <StyledSearch>
          <Search
            placeholder={t('layout:searchCitiesAndAreas')}
            mb={theme.space[1]}
            width="100%"
            onChange={filterDeliveryZonesByParam}
            value={filterParam}
            disabled={isLoading || isLoadingCountries}
          />
        </StyledSearch>
        <StyledCountryWrapper>
          <Countries
            setSelectedCountry={onCountrySelected}
            selectedCountry={countryDetails}
            countries={countries}
            isLoading={isLoadingCountries}
            isArabic={isArabic}
          />
        </StyledCountryWrapper>
      </StyledWrapper>
      <StyledDeliverListWrapper>{deliverZonesList()}</StyledDeliverListWrapper>
    </>
  );
};

export default DeliveryList;
