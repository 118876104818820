import { normalizationDictionary } from '../constants';

/**
 *
 * This function normalize arabic words by replacing some chars with another
 * for example 'أ' with 'ا'
 * @param str
 */
export const normalizeString = str => str.replace(/[أىآإؤةئ]/g, char => normalizationDictionary[char] || char);

/**
 * Filters the given list based on whether or not they include the `match` string.
 *
 * The filter happens by mapping each element using the provided `mapper`
 * which accepts list element and returns a string, if **any of the returned strings** matched `match`
 * then the element in hand is returned from the filter. Else, the element is not returned.
 *
 * @param list the list of items to search.
 * @param match the string to search for.
 * @param mapper a function that accepts an element and returns an array of strings.
 */
export const stringMatch = <T>(
  list: T[],
  match: string,
  mapper: (element: T) => string[] = (value: any) => [value] as string[],
): T[] =>
  list.filter(element => {
    const values = mapper(element);
    return values.some(value => value.toLowerCase().includes(match.toLowerCase()));
  });
