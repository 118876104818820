import { gql } from 'graphql-request';

const countryDetailsWithAreasQuery = gql`
  query ($subdomain: String!, $id: ID) {
    store(subdomain: $subdomain) {
      id
      countries(id: $id) {
        code
        currency
        flag
        id
        lat
        lng
        timeZone
        titleAr
        titleEn
        cities {
          id
          lat
          lng
          titleAr
          titleEn
          areas {
            id
            lat
            lng
            titleAr
            titleEn
            cityId
            deliveryZone {
              branchId
              id
              areaId
              deliveryTime
              minimumOrder
            }
          }
        }
      }
    }
  }
`;

export default countryDetailsWithAreasQuery;
