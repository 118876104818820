import { useQuery } from '../../utils';
import { storeCountriesQuery } from '../../queries';
import { QueryHookResponse } from '../../types/internal';
import ServiceConfigs from '../../config';
import { Store } from '../../types/generated/index';

type UseCountriesType = ({ initialData }?: { initialData?: Store }) => QueryHookResponse<Store>;

const useCountries: UseCountriesType = ({ initialData } = {}) =>
  useQuery({
    query: storeCountriesQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain() },
    handler: (res: any) => res.store,
    config: { initialData },
  });

export default useCountries;
