import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledSearch = styled.div`
  margin: 0 ${({ theme }) => theme.space[4]}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

export const StyledArea = styled.div<{ isActive: boolean }>`
  padding: ${({ theme }) => `${theme.space[4]}px 0`};
  border-top: 1px solid ${({ theme }) => theme.color.border};
  list-style: none;
  cursor: pointer;
  position: relative;
  ${props =>
    props.isActive &&
    `
    &:before {
      content: '';
      top: 0px;
      bottom: 0px;
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: ${props.theme.color.primary};
      ${props.theme.direction === 'ltr' && `left: -${props.theme.space[4]}px`};
      ${props.theme.direction === 'rtl' && `right: -${props.theme.space[4]}px`};
    }  
  `}
`;

export const StyledDeliverListWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledCountryWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.color.background};
`;

export const StyledAreaWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;
