import styled from 'styled-components';

export default {
  StyledCountryWrapper: styled.div`
    cursor: pointer;
  `,

  StyledCountryShimmer: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    padding: 0 10px;
    width: 100%;
  `,
};
